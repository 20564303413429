import React from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router";
import { ANALYTICS_MODULE } from "constants/index";
import { UploadUserVideoModal } from "app/components/UploadUserVideoModal";
import LinkButton from "app/components/Button/LinkButton";
import Flex from "app/components/Flex";
import { SubmitBarWrapper, SubmitButtonWrapper } from "./styles";

const SubmitBar = ({ classId, challengeActive = false }) => {
  const { id } = useParams();
  return (
    <>
      <SubmitBarWrapper
        width="100%"
        justifyContent="flex-end"
        alignItems="center"
        height="72px"
        background="white"
        bottom={0}
        position="fixed"
      />
      <SubmitButtonWrapper
        width="475px"
        height="48px"
        mr={4}
        mb="12px"
        position="fixed"
        bottom="0"
        right="0"
      >
        <Flex flex={1}>
          <LinkButton
            to={`/class/${classId}`}
            variant="hollowBlue"
            padding="12px 0"
            width="100%"
            mr={2}
          >
            LEARN THE DANCE
          </LinkButton>
        </Flex>
        <Flex flex={1}>
          <UploadUserVideoModal
            challengeId={id}
            challengeActive={challengeActive}
            fromModule={ANALYTICS_MODULE.challenge_detail}
            buttonProps={{
              padding: "12px 0",
            }}
          />
        </Flex>
      </SubmitButtonWrapper>
    </>
  );
};

SubmitBar.propTypes = {
  challengeActive: PropTypes.bool,
  classId: PropTypes.string,
};

export default SubmitBar;
