import styled from "styled-components";
import Flex from "app/components/Flex";

const SubmitBarWrapper = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const SubmitButtonWrapper = styled(Flex)`
  ${({ theme }) => theme.mediaQueries.ltmd} {
    width: 70%;
  }

  ${({ theme }) => theme.mediaQueries.ltsm} {
    width: 90%;
    margin-right: 5%;
  }
`;

export { SubmitBarWrapper, SubmitButtonWrapper };
